export default function Footer() {
  return (
    <div>
      <div className="hidden mb-10 mt-20 md:flex flex-col w-full justify-center items-center">
        <h1 className="text-white">MineFury is not affiliated with Microsoft, Mojang, Nintendo, GameFreak and The Pokemon Company</h1>
        <h1 className="text-[0.8rem] text-gray-300">NOT AN OFFICIAL MINECRAFT PRODUCT, NOT APPROVED BY OR ASSOCIATED WITH MOJANG OR MICROSOFT</h1>

        <h1 className="mt-4 text-[0.8rem] text-gray-300">Website owned & developed by Canvas Gaming LTD © 2025</h1>
      </div>

      <div className="mt-20 md:hidden flex-col w-full justify-center items-center">
      </div>
    </div>
  )
}
