import styled from "styled-components";

// Function to convert hex color to rgba
const hexToRgba = (hex, alpha = 0.5) => {
  // Remove the hash if present
  hex = hex.replace('#', '');

  // Convert the hex color to RGB
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Return the rgba value with the desired alpha
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// Use the function inside your styled component
const SlideButton = styled.button`
  position: relative;
  padding: 0.5rem 1.8rem;
  font-size: 1rem;
  color: white;
  background: none;
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
  border-bottom: 2px solid ${(props) => props.colors[0]};

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      45deg, 
      ${(props) => hexToRgba(props.colors[1], 0.5)}, 
      ${(props) => hexToRgba(props.colors[0], 0.5)}
    );
    transition: transform 0.25s ease;
    transform: skewX(0deg);
  }

  &:hover::before {
    transform: translateY(100%);
  }

  &:hover .icon {
    transform: translateX(5px);
  }
`;


export default function NavElement(props) {
  const { text, colors } = props;

  return (
    <div className="button-wrapper">
      {/* Pass the colors prop to SlideButton */}
      <SlideButton colors={colors}>
        <span className="slide-content font-bold">{text}</span>
      </SlideButton>
    </div>
  );
}
