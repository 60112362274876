import NavBar from "./NavBar";
import NavLogo from "./NavLogo";
import logo from "../../assets/logo.png";
import JoinMinecraft from "./JoinMinecraft";
import JoinDiscord from "./JoinDiscord";

export default function Header() {
  // return (
  //   <div className="w-full h-[6rem] bg-black bg-opacity-70">
  //     <NavLogo />
  //     <NavBar />
  //   </div>
  // )

  return (
    <div className="select-none relative z-[1] top-0 flex w-full justify-center mt-[3rem]">
      <img className="animate-logo-pulse absolute
                      hidden sm:flex
                      top-24 xl:-top-4
                      sm:w-[12rem] md:w-[15rem] xl:w-[20rem] 2xl:w-[25rem]"
           src={logo}
           alt="logo"/>
      <img className="animate-logo-pulse absolute
                      sm:hidden
                      top-[-1.5rem] left-[2rem]
                      w-[8rem]"
           src={logo}
           alt="logo"/>
      <div className="flex flex-col sm:flex-row w-full items-end justify-between">
        <JoinMinecraft/>
        <JoinDiscord/>
      </div>
    </div>
  )
}
