import storeBanner from "../../assets/store-banner.png";

export default function StoreBanner(props) {

  return (
    <div>
      <div className="hidden w-full h-[25rem] max-h-[25rem] md:grid grid-cols-[40%_auto]">
        <div className="relative p-5 flex flex-col gap-4 w-full h-full bg-opacity-50 overflow-hidden">
          <h1 className="md:text-[1.4rem] xl:text-[1.8rem] font-black text-white truncate">
            Support MineFury
          </h1>
          <p className="text-[1rem] text-gray-200 line-clamp-6">
            Want to help keep MineFury growing while supporting your favorite creators like <span className="font-bold">JeromeASF & The Buddies</span>?
            By purchasing in-game items, you’re directly contributing to the server’s development, new updates, and
            exciting features!

            Check out our store below and grab awesome rewards while supporting the community!
          </p>

          <div className="z-0 absolute bottom-20 left-0 flex w-full justify-center">
            <button className="minecraft-button px-4"
                    onClick={() => {
                      if (window === undefined) return
                      window.open("https://store.minefury.net", "_blank")
                    }}>
              <span className="text-[1.2rem]">Visit the Store</span>
            </button>
          </div>
        </div>
        <div className="relative flex h-[25rem] bg-opacity-50">
          <img className="pointer-events-none w-full h-full object-cover"
               src={storeBanner} alt="image1"
               style={{
                 WebkitMaskImage: "linear-gradient(to right, transparent 0%, black 30%)",
                 maskImage: "linear-gradient(to right, transparent 0%, black 40%)"
               }}
          />
        </div>
      </div>

      <div className="md:hidden relative flex h-[25rem] bg-opacity-50">
        <img className="absolute pointer-events-none w-full h-full object-cover"
             src={storeBanner} alt="image1"
             style={{
               WebkitMaskImage: "linear-gradient(to right, transparent 10%, black 90%)",
               maskImage: "linear-gradient(to right, transparent 10%, black 90%)"
             }}
        />

        <div className="absolute pr-24 p-5 flex flex-col gap-4 w-full h-full bg-opacity-50 overflow-hidden">
          <h1 className="text-[1.2rem] font-black text-white truncate brightness-150">
            Support MineFury
          </h1>
          <p className="text-[1rem] text-gray-200 line-clamp-6 brightness-150">
            Want to help keep MineFury growing while supporting your favorite creators like <span className="font-bold">JeromeASF & The Buddies</span>?
            By purchasing in-game items, you’re directly contributing to the server’s development, new updates, and
            exciting features!

            Check out our store below and grab awesome rewards while supporting the community!
          </p>

          <div className="z-0 absolute bottom-20 left-0 flex w-full justify-center">
            <button className="minecraft-button px-2.5"
                    onClick={() => {
                      if (window === undefined) return
                      window.open("https://store.minefury.net", "_blank")
                    }}>
              <span className="text-[1rem]">Visit the Store</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
