import grass from "../../assets/grass.png";
import {useState} from "react";

export default function JoinMinecraft() {
  const [copied, setCopied] = useState(false);

  const handleMinecraftClick = () => {
    navigator.clipboard.writeText("play.minefury.net");
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <div
      className="w-fit pixel-image toast-border animate-toast-pulse cursor-pointer shadow-lg shadow-white/20 hover:shadow-white/40
                 mr-[-2.5rem] sm:ml-[0rem] lg:ml-[2rem] xl:ml-[4rem] 2xl:ml-[8rem]
                 mt-[-2.5rem] sm:mt-[0rem] mb-[-0.5rem] sm:mb-[0rem]
                 scale-[0.65] sm:scale-[0.8] md:scale-[0.9] 2xl:scale-[1]"
      style={{
        borderWidth: "8px",
        borderStyle: "solid",
        borderColor: "#555",
        borderRadius: "0",
        backgroundColor: "#212121",
        backgroundClip: "padding-box",
        padding: "0.25em 0.25em 0.125em 0.25em",
        minWidth: "320px",
        float: "right",
        fontFamily: "minecraft",
        borderImage: "url('https://minecraft.wiki/images/Achievement_background.png?dd4ed') 8 fill repeat",
      }}
      onClick={handleMinecraftClick}
    >
      <div className="flex items-center">
        <div className="ml-1 flex w-[2.5rem] h-full justify-center items-center">
          <img className="aspect-square" src={grass} alt="alt"/>
        </div>
        <div className="ml-4 leading-5 text-[1.2rem]">
          <h1 className="text-yellow-300 brightness-110">Join MineFury today!</h1>
          <h1 className="mb-1 text-white">
            {copied ? "✔ Copied to clipboard" : "play.minefury.net"}
          </h1>
        </div>
      </div>
    </div>
  )
}
