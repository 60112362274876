import tutorial from "../../assets/tutorial.mp4";

export default function JoinTutorial(props) {
  const { setJoinTutorial } = props;

  return (
    <div className="z-[10] fixed top-0 left-0 w-screen h-screen bg-black"
         onTouchStart={() => setJoinTutorial(false)}
         onClick={() => setJoinTutorial(false)}
    >
      <div className="select-none z-[-1] absolute flex flex-col w-screen h-screen justify-center items-center">
        <p className="text-[1.4rem] text-white font-bold">
          TUTORIAL
        </p><p className="text-[1.2rem] text-white">
          How to Join the Server
        </p>

        <video muted loop autoPlay className="mt-6 w-full px-4 md:w-2/3 md:px-0">
          <source src={tutorial} type="video/mp4"/>
        </video>

        <p className="mt-16 text-[1.3rem] text-white">
          Below are installation tutorials
        </p>
        <div className="mt-5 flex gap-6">
          <button className="minecraft-button px-2.5 md:px-4"
                  onClick={() => {
                    if (window === undefined) return
                    window.open("https://www.youtube.com/watch?v=7U4Y-YH6poY", "_blank")
                  }}>
            <span className="text-[0.9rem] md:text-[1.3rem]">Cobblemon Installation</span>
          </button>

          <button className="minecraft-button px-2.5 md:px-4"
                  onClick={() => {
                    if (window === undefined) return
                    window.open("https://www.youtube.com/watch?v=kuTmAlZhsXk", "_blank")
                  }}>
            <span className="text-[0.9rem] md:text-[1.3rem]">Pixelmon Installation</span>
          </button>
        </div>

      </div>
    </div>
  )
}
