import discord from "../../assets/discord.png";

export default function JoinDiscord() {
  const handleDiscordClick = () => {
    if (window === undefined) return
    window.open("https://discord.gg/minefury", "_blank");
  };

  return (
    <div
      className="w-fit pixel-image toast-border animate-toast-pulse cursor-pointer shadow-lg shadow-white/20 hover:shadow-white/40
                 mr-[-2.5rem] sm:mr-[0rem] lg:mr-[2rem] xl:mr-[4rem] 2xl:mr-[8rem]
                 scale-[0.65] sm:scale-[0.8] md:scale-[0.9] 2xl:scale-[1]"
      style={{
        borderWidth: "8px",
        borderStyle: "solid",
        borderColor: "#555",
        borderRadius: "0",
        backgroundColor: "#212121",
        backgroundClip: "padding-box",
        padding: "0.25em 0.25em 0.125em 0.25em",
        minWidth: "320px",
        float: "right",
        fontFamily: "minecraft",
        borderImage: "url('https://minecraft.wiki/images/Achievement_background.png?dd4ed') 8 fill repeat",
      }}
      onClick={handleDiscordClick}
    >
      <div className="flex items-center">
        <div className="ml-1 flex w-[2.5rem] h-full justify-center items-center">
          <img className="scale-[1.4] aspect-square" src={discord} alt="alt"/>
        </div>
        <div className="ml-4 leading-5 text-[1.2rem]">
          <h1 className="text-yellow-300 brightness-110">Join MineFury discord!</h1>
          <h1 className="mb-1 text-white">
            discord.minefury.net
          </h1>
        </div>
      </div>
    </div>
  )
}
