import pixelmonBanner from "../../assets/pixelmon-banner.png";

export default function PixelmonBanner() {
  return (
    <div>
      <div className="hidden w-full h-[25rem] md:grid grid-cols-[auto_40%]">
        <div className="relative flex h-[25rem] bg-opacity-50">
          <img className="pointer-events-none w-full h-full object-cover"
               src={pixelmonBanner} alt="image1"
               style={{
                 WebkitMaskImage: "linear-gradient(to left, transparent 0%, black 30%)",
                 maskImage: "linear-gradient(to left, transparent 0%, black 40%)"
               }}
          />
        </div>
        <div className="relative p-5 flex flex-col gap-4 w-full h-full bg-opacity-50">
          <h1 className="md:text-[1.4rem] xl:text-[1.8rem] font-black text-white text-end truncate">
            What is MineFury?
          </h1>
          <p className="xl:text-[1rem] text-gray-200 text-end line-clamp-6">
            MineFury is a premium <span className="font-bold">Cobblemon</span> and <span
            className="font-bold">Pixelmon</span> server, home to <span
            className="font-bold">JeromeASF & The Buddies</span>!
            Enjoy a custom world, active community, and Pokémon inspired adventure.
            <br/>
            Skyblock and Prisons are coming soon, stay tuned!
          </p>

          <div className="z-0 absolute bottom-20 left-0 flex gap-4 w-full justify-center">
            <button className="minecraft-button px-4"
                    onClick={() => {
                      if (window === undefined) return
                      window.open("https://www.technicpack.net/modpack/pixelmonfury.1992837", "_blank")
                    }}>
              <span className="xl:text-[1.2rem]">Pixelmon Modpack</span>
            </button>
            <button className="minecraft-button px-4"
                    onClick={() => {
                      if (window === undefined) return
                      window.open("https://www.technicpack.net/modpack/cobblemon-fury.2001818", "_blank")
                    }}>
              <span className="xl:text-[1.2rem]">Cobblemon Modpack</span>
            </button>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="w-full h-[25rem] md:hidden grid-cols-[auto_40%]">
        <div className="relative flex h-[25rem] bg-opacity-50">
          <img className="absolute pointer-events-none w-full h-full object-cover"
               src={pixelmonBanner} alt="image1"
               style={{
                 WebkitMaskImage: "linear-gradient(to left, transparent 10%, black 90%)",
                 maskImage: "linear-gradient(to left, transparent 10%, black 90%)"
               }}
          />

          <div className="absolute pl-24 p-5 flex flex-col gap-4 w-full h-full bg-opacity-50">
            <h1 className="text-[1.2rem] font-black text-white text-end truncate brightness-150">
              What is MineFury?
            </h1>
            <p className="text-[1rem] text-gray-200 text-end line-clamp-6 brightness-150">
              MineFury is a premium <span className="font-bold">Cobblemon</span> and <span
              className="font-bold">Pixelmon</span> server, home to <span
              className="font-bold">JeromeASF & The Buddies</span>!
              Enjoy a custom world, active community, and Pokémon inspired adventure.
              <br/>
              Skyblock and Prisons are coming soon, stay tuned!
            </p>

            <div className="z-0 absolute bottom-20 left-0 flex gap-4 w-full justify-center">
              <button className="minecraft-button px-2.5"
                      onClick={() => {
                        if (window === undefined) return
                        window.open("https://www.technicpack.net/modpack/pixelmonfury.1992837", "_blank")
                      }}>
                <span className="text-[1rem]">Pixelmon Modpack</span>
              </button>
              <button className="minecraft-button px-2.5"
                      onClick={() => {
                        if (window === undefined) return
                        window.open("https://www.technicpack.net/modpack/cobblemon-fury.2001818", "_blank")
                      }}>
                <span className="text-[1rem]">Cobblemon Modpack</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
