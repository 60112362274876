import Header from "./components/header/Header";
import animation from "./assets/animation.mp4";
import VanillaBanner from "./components/banner/VanillaBanner";
import PixelmonBanner from "./components/banner/PixelmonBanner";
import Footer from "./components/footer/Footer";
import {useState} from "react";
import JoinTutorial from "./components/tutorial/JoinTutorial";
import StoreBanner from "./components/banner/StoreBanner";

function App() {
  const [ joinTutorial, setJoinTutorial ] = useState(false);

  return (
    <div className="">
      {/*<div className="z-[100] fixed top-2 left-2 text-white">*/}
      {/*  <span className="hidden sm:flex">sm</span>*/}
      {/*  <span className="hidden md:flex">md</span>*/}
      {/*  <span className="hidden lg:flex">lg</span>*/}
      {/*  <span className="hidden xl:flex">xl</span>*/}
      {/*  <span className="hidden 2xl:flex">2xl</span>*/}
      {/*</div>*/}
      
      <div className="z-[-2] fixed top-0 left-0 w-screen h-screen bg-black"/>

      <div className="hidden sm:flex select-none z-[-1] absolute top-20">
        <div className="video-container">
          <video muted loop autoPlay className="video-with-shadow">
            <source src={animation} type="video/mp4"/>
          </video>
          <div className="gradient-top"></div>
          <div className="gradient-bottom"></div>
          <div className="gradient-left"></div>
          <div className="gradient-right"></div>
        </div>
      </div>

      <Header />

      {(joinTutorial) && (<JoinTutorial setJoinTutorial={setJoinTutorial} />)}

      <div className="mt-[3rem] sm:mt-[20rem] lg:px-16 xl:px-32">
        <div className="flex flex-col gap-10">
          <VanillaBanner setJoinTutorial={setJoinTutorial} />
          <PixelmonBanner />
          <StoreBanner />
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default App;
