import vanillaBanner from "../../assets/vanilla-banner.png";

export default function VanillaBanner(props) {
  const { setJoinTutorial } = props;

  return (
    <div className="">
      <div className="hidden md:grid w-full h-[25rem] max-h-[25rem] grid-cols-[40%_auto]">
        <div className="relative p-5 flex flex-col gap-4 w-full h-full bg-opacity-50 overflow-hidden">
          <h1 className="md:text-[1.4rem] xl:text-[1.8rem] font-black text-white truncate">
            How to join the server?
          </h1>
          <p className="xl:text-[1rem] text-gray-200 line-clamp-6">
            To join the server, use Minecraft: Java Edition and connect to play.minefury.net. Make sure you have either
            the Pixelmon or Cobblemon mod installed before joining.
          </p>

          <div className="z-0 absolute bottom-20 left-0 flex w-full justify-center">
            <button className="minecraft-button px-4"
                    onClick={() => setJoinTutorial(true)}>
              <span className="xl:text-[1.2rem]">Watch Tutorial</span>
            </button>
          </div>
        </div>
        <div className="relative flex h-[25rem] bg-opacity-50">
          <img className="pointer-events-none w-full h-full object-cover"
               src={vanillaBanner} alt="image1"
               style={{
                 WebkitMaskImage: "linear-gradient(to right, transparent 0%, black 30%)",
                 maskImage: "linear-gradient(to right, transparent 0%, black 40%)"
               }}
          />
        </div>
      </div>

      {/* MOBILE */}
      <div className="md:hidden relative flex h-[25rem] bg-opacity-50">
        <img className="absolute pointer-events-none w-full h-full object-cover"
             src={vanillaBanner} alt="image1"
             style={{
               WebkitMaskImage: "linear-gradient(to right, transparent 10%, black 90%)",
               maskImage: "linear-gradient(to right, transparent 10%, black 90%)"
             }}
        />
        <div className="absolute pr-24 p-5 flex flex-col gap-4 w-full h-full bg-opacity-50 overflow-hidden">
          <h1 className="text-[1.2rem] font-black text-white truncate brightness-150">
            How to join the server?
          </h1>
          <p className="text-[1rem] text-gray-200 line-clamp-6 brightness-150">
            To join the server, use Minecraft: Java Edition and connect to play.minefury.net. Make sure you have either
            the Pixelmon or Cobblemon mod installed before joining.
          </p>

          <div className="z-0 absolute bottom-20 left-0 flex w-full justify-center">
            <button className="minecraft-button px-2.5"
                    onClick={() => setJoinTutorial(true)}>
              <span className="text-[1rem]">Watch Tutorial</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
